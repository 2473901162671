import styles from "./MisyonumuzContainer.module.css";
const MisyonumuzContainer = () => {
  return (
    <div
      className={`${styles.misyonumuzzContainer} mb-5 mt-3  container-xl px-5`}
    >
      <p className={styles.heading}>Misyonumuz</p>
      <hr />
      <div className="mt-5   ">
        Firmamız, <strong>finansal mükemmeliyetin</strong> teminatıdır.
        Misyonumuz, müşterilerimize mali konularda <strong>güvenilir</strong>,
        <strong> profesyonel</strong> ve <strong>yenilikçi çözümler</strong>{" "}
        sunmak, onların <strong>finansal başarılarını artırmak</strong> ve
        <strong> işlerini büyütmelerine yardımcı olmaktır</strong>.
        <br />
        <br />
        Biz,
        <strong> dürüstlük, şeffaflık ve etik değerlere dayalı</strong> bir
        yaklaşım benimsemekteyiz.{" "}
        <strong>Müşteri odaklı bir hizmet anlayışıyla </strong>
        çalışarak, onların özel ihtiyaçlarını anlamak ve bu ihtiyaçları
        karşılamak için çaba sarf etmekteyiz. Aynı zamanda, yasal gerekliliklere
        ve en iyi uygulamalara tam uyum sağlayarak{" "}
        <strong> mükemmel bir hizmet sunmayı taahhüt ediyoruz.</strong> <br />
        <br />
        <strong> Ekibimizin deneyimi ve uzmanlığı,</strong> müşterilerimize
        sadece finansal açıdan değil, aynı zamanda{" "}
        <strong> stratejik birer ortak</strong> olarak da hizmet vermemize
        olanak tanır.{" "}
        <strong>Müşterilerimizin başarısı, bizim başarımızdır.</strong> Biz,
        finansal düzenlemelerin karmaşıklığını anlamaktayız ve müşterilerimizi
        bu karmaşıklığı yönetmelerine yardımcı olmak için buradayız. Misyonumuz,
        mali yönetimde mükemmeliyetin ve güvenin temsilcisidir.
      </div>
    </div>
  );
};

export default MisyonumuzContainer;
