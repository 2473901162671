import { Link } from "react-router-dom";
import styles from "./SectionTwo.module.css";
import { HizmetlerData } from "../../../Data/Data";
const SectionTwo = () => {
  const hizmetler = HizmetlerData;
  return (
    <section className={`${styles.sectionTwo} container-xl`}>
      <div>
        <h2 className="text-center">Hizmetlerimiz</h2>
        <div className="d-flex align-items-center justify-content-center w-50 m-auto">
          <span className={styles.line}></span>
          <img
            style={{ width: "1.5rem" }}
            src="/icons/icon-star.webp"
            alt="star"
          />
          <span className={styles.line}></span>
        </div>
        <div className="row mt-5 justify-content-center">
          {hizmetler.map((hizmet) => (
            <div
              key={hizmet.id}
              className=" mb-3 col-lg-4  col-md-6 col-sm-12 text-center"
            >
              <Link to={hizmet.path}>
                <div className={`${styles.card} card position-relative h-100 `}>
                  <img
                    className={styles.linkIcon}
                    src="/icons/icon-link.webp"
                    alt={hizmet.fullTitle}
                  />
                  <div
                    className={`${styles.cardHeader} card-header position-relative`}
                  >
                    <img
                      className={styles.serviceIcon}
                      src={`/icons/icon-service-${hizmet.id + 1}.webp`}
                      alt={hizmet.homeTitle}
                    />
                    <h3 className="h4 card-title">{hizmet.homeTitle}</h3>
                  </div>
                  <div className={`${styles.cardBody} card-body`}>
                    <p className="card-text">{hizmet.homeDescription}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
