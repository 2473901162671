import { Helmet } from "react-helmet";
import VizyonumuzContainer from "../../Components/Vizyonumuz/VizyonumuzContainer";

const Vizyonumuz = () => {
  return (
    <div>
      <Helmet>
        <title>Vizyonumuz - Bağcı Yeminli Mali Müşavirlik </title>
        <meta
          name="description"
          content="Firmamızın vizyonu, finansal mükemmelliği ve sürdürülebilir büyümeyi teşvik eden bir lider olmak, müşterilerimizin önceliğini benimseyerek, profesyonel bir hizmet sunmaktır."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link rel="canonical" href="https://www.bagciymm.com/vizyonumuz" />
      </Helmet>
      <VizyonumuzContainer />
    </div>
  );
};

export default Vizyonumuz;
