import { Outlet } from "react-router-dom";
import NavbarContainer from "../Components/Layout/Navbar/Container/NavbarContainer";
import FooterContainer from "../Components/Layout/Footer/Container/FooterContainer";

const Layout = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh ",
      }}
    >
      <NavbarContainer />
      <div
        style={{
          flexGrow: "1",
          flexShrink: "0",
          flexBasis: "auto",
        }}
      >
        <Outlet />
      </div>
      <FooterContainer />
    </div>
  );
};

export default Layout;
