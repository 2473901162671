import styles from "./VizyonumuzContainer.module.css";

const VizyonumuzContainer = () => {
  return (
    <div
      className={`${styles.vizyonumuzContainer} mb-5 mt-3  container-xl px-5`}
    >
      <p className={styles.heading}>Vizyonumuz</p>
      <hr />
      <div className="mt-5   ">
        Firmamızın vizyonu, <strong>finansal mükemmelliği</strong> ve
        <strong>sürdürülebilir büyümeyi</strong> teşvik eden bir lider olmaktır.
        İçinde bulunduğumuz dinamik iş dünyasında, müşterilerimizin her zaman
        önce gelmesi gerektiğine inanıyoruz.
        <br />
        <br />
        Amacımız, müşterilerimizin finansal hedeflerini gerçekleştirmelerine
        yardımcı olurken, <strong>en yüksek etik standartlara</strong> ve
        <strong>profesyonellik</strong> ilkelerine bağlı kalmaktır. <br />
        <br />
        Her gün, <strong>müşteri memnuniyetini sağlamak için</strong> çaba sarf
        ediyor ve işimizi gelecek nesillere daha sağlam ve sürdürülebilir bir
        şekilde bırakmayı taahhüt ediyoruz.
        <br />
        <br />
        Vizyonumuz, iş dünyasının karmaşıklığına ve değişkenliğine rağmen,
        sürekli olarak <strong>en iyi hizmeti sunarak</strong> müşterilerimizin
        güvenini kazanmak ve korumakla ilgilidir. Biz, finansal başarıya giden
        yolculukta müşterilerimizle birlikte olmayı ve onların başarısını
        paylaşmayı daima hedefliyoruz. <br />
        <br />
        Gelecekte, sadece finansal danışmanlık alanında değil, aynı zamanda
        toplumsal sorumlulukları yerine getirmek ve toplumumuza katkıda bulunmak
        için öncü olmayı sürdüreceğiz.{" "}
        <strong>
          İş dünyasının ve toplumun daha iyi bir geleceğe ulaşmasına yardımcı
          olacak bir vizyonla
        </strong>{" "}
        hareket ediyoruz.
      </div>
    </div>
  );
};

export default VizyonumuzContainer;
