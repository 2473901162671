import { Link } from "react-router-dom";
import styles from "./FooterContainer.module.css";

const FooterContainer = () => {
  return (
    <footer className={`${styles.footerContainer} d-flex flex-column   `}>
      <div className={`${styles.imgWrapper}`}>
        <img src="/images/logo2.webp" alt="Bağcı YMM Kayseri" />
      </div>
      <div className={`${styles.textWrapper} row  w-100  `}>
        <ul className={`${styles.list} col-md-4 col-sm-12 `}>
          <p className={styles.listHeader}>Hızlı Erişim</p>
          <Link to="/bagci-ymm">
            <li>Bağcı YMM</li>
          </Link>
          <Link to="/misyonumuz">
            <li>Misyonumuz</li>
          </Link>
          <Link to="/vizyonumuz">
            <li>Vizyonumuz</li>
          </Link>
          <Link to="/duyurular">
            <li>Duyurular</li>
          </Link>
          <Link to="/haberler">
            <li>Haberler</li>
          </Link>
        </ul>
        <ul className={`${styles.list} col-md-4 col-sm-12`}>
          <p className={styles.listHeader}>Hizmetlerimiz</p>
          <Link to="/hizmetler/kdv-iadesi-tasdik-raporu">
            <li>KDV İadesi Tasdik Raporu</li>
          </Link>
          <Link to="/hizmetler/tam-tasdik">
            <li>Tam Tasdik Hizmeti</li>
          </Link>
          <Link to="/hizmetler/ozel-amacli-tam-tasdik">
            <li>Özel Amaçlı Tam Tasdik</li>
          </Link>
          <Link to="/hizmetler/gelir-ve-kurumlar-vergisi-iadeleri">
            <li>Vergi İadeleri</li>
          </Link>
          <Link to="/hizmetler/tesvik-mevzuati-ve-uygulamalari">
            <li>Teşvik Mevzuatı ve Uygulamaları</li>
          </Link>
          <Link to="/hizmetler/vergi-incelemesi-danismanligi">
            <li>Vergi İncelemesi Danışmanlığı</li>
          </Link>

          <Link to="/hizmetler/denetim-hizmetleri">
            <li>Denetim Hizmetleri</li>
          </Link>
          <Link to="/hizmetler/egitim-hizmetleri">
            <li>Eğitim Hizmetleri</li>
          </Link>
        </ul>
        <ul
          className={`${styles.list} ${styles.contactList}   col-md-4 col-sm-12`}
        >
          <p className={styles.listHeader}>Bize Ulaşın</p>

          <Link
            className={styles.contactLink}
            target="_blank"
            to="https://api.whatsapp.com/send/?phone=905067961090"
          >
            <img src="/icons/icon-whatsapp.png" alt="Bağcı Ymm Whatsapp" />
            Whatsapp
          </Link>
          <Link
            className={styles.contactLink}
            target="_blank"
            to="mailto:info@bagciymm.com"
          >
            {" "}
            <img src="/icons/icon-mail.webp" alt="Bağcı Ymm Mail" />
            E-posta
          </Link>

          <Link
            className={styles.contactLink}
            target="_blank"
            to="https://maps.app.goo.gl/kgXCpCHDqAa15gKh7"
          >
            {" "}
            <img src="/icons/icon-location.webp" alt="Bağcı YMM Kayseri Ofis" />
            Kayseri Ofis
          </Link>
          <Link
            className={styles.contactLink}
            target="_blank"
            to="tel:03525045626"
          >
            {" "}
            <img src="/icons/icon-phone.webp" alt="Bağcı Ymm Telefon" />
            Telefon
          </Link>
          <Link className={styles.contactLink} target="_blank" to="/sss">
            {" "}
            <img src="/icons/icon-question.webp" alt="SSS" />
            Sıkça Sorulan Sorular
          </Link>
          <li className={styles.contactLinks__socialLinks}>
            <Link
              target="_blank"
              to="https://twitter.com/bagciymm"
              className={styles.twitterLogo}
            >
              {" "}
              <img
                src="/icons/twitter.svg"
                alt="Twitter - Bağcı Yeminli Mali Müşavirlik "
              />
            </Link>
            <Link
              target="_blank"
              to="https://www.instagram.com/bagciymm"
              className={styles.instagramLogo}
            >
              <img
                src="/icons/instagram.png"
                alt="İnstagram - Bağcı Yeminli Mali Müşavirlik "
              />
            </Link>
            <Link
              target="_blank"
              to="https://www.facebook.com/profile.php?id=61552560837312"
              className={styles.facebookLogo}
            >
              {" "}
              <img
                src="/icons/facebook.png"
                alt="Facebook - Bağcı Yeminli Mali Müşavirlik "
              />
            </Link>
          </li>
        </ul>
      </div>
      <div className={`${styles.credits} pt-5 `}>
        <Link
          rel="nofollow"
          target="_blank"
          to="https://www.linkedin.com/in/firatkill"
          className=" text-center"
        >
          <small>Designed By Fırat Kıl.</small>
        </Link>
        <small className=" text-center">
          © Copyright 2023 <strong>Bağcı Yeminli Mali Müşavirlik</strong> Tüm
          Hakları Saklıdır.
        </small>
        <small className=" text-center">
          Icons made by{" "}
          <Link to="https://www.flaticon.com/authors/freepik" rel="nofollow">
            Freepik
          </Link>{" "}
          from{" "}
          <Link rel="nofollow" to="https://www.flaticon.com/">
            flaticon
          </Link>
        </small>
      </div>
    </footer>
  );
};

export default FooterContainer;
