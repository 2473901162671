import { Helmet } from "react-helmet";
import SectionOne from "../Components/Anasayfa/SectionOne/SectionOne";
import SectionTwo from "../Components/Anasayfa/SectionTwo/SectionTwo";
import SectionThree from "../Components/Anasayfa/SectionThree/SectionThree";
import SectionFour from "../Components/Anasayfa/SectionFour/SectionFour";
import SectionFive from "../Components/Anasayfa/SectionFive/SectionFive";
import SectionSix from "../Components/Anasayfa/SectionSix/SectionSix";

const Anasayfa = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bağcı Yeminli Mali Müşavirlik - Kayseri YMM Şirketi</title>
        <meta
          name="description"
          content="Bağcı Yeminli Mali Müşavirlik - Kayseri YMM Şirketi
Yeminli Mali Müşavirlik Alanında müşterilerimize her daim güncel ve doğru bilgi sağlamayı amaçlıyoruz."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
      </Helmet>
      <SectionOne />
      <div className="container-xl">
        <SectionTwo />
        <SectionThree />
      </div>
      <SectionFour />

      <SectionFive />
      <SectionSix />
    </>
  );
};

export default Anasayfa;
