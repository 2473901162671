import { useSelector } from "react-redux";
import styles from "./SSSContainer.module.css";
const SSSContainer = () => {
  const sorular = useSelector((state) => state.content.sorular);
  return (
    <div className="container-lg mt-3 mb-3">
      <p className={styles.heading}>Sıkça Sorulan Sorular</p>
      <hr />

      <div
        className={`${styles.accordionContainer} accordion accordion-flush`}
        id="accordionContainer"
      >
        {sorular.map((soru) => (
          <div
            key={soru.id}
            className={`${styles.accordionItem} accordion-item`}
          >
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#accordion-${soru.id}`}
                aria-expanded="false"
                aria-controls={`#accordion-${soru.id}`}
              >
                {soru.title}
              </button>
            </h2>
            <div
              id={`accordion-${soru.id}`}
              data-bs-parent="#accordionContainer"
              className={`${styles.accordionTextContainer} accordion-collapse collapse`}
            >
              <div className="accordion-body">
                <p>{soru.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SSSContainer;
