import { Helmet } from "react-helmet";

const DenetimHizmetleri = () => {
  return (
    <div>
      <Helmet>
        <title>Denetim Hizmetleri - Bağcı Yeminli Mali Müşavirlik </title>
        <meta
          name="description"
          content="Şirketinizin mali yapısını objektif bir şekilde değerlendirerek denetim hizmetleri sunuyoruz. Vergisel avantajlardan yararlanmanızı sağlıyor, potansiyel vergi incelemelerine karşı riski azaltıyoruz."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link
          rel="canonical"
          href="https://www.bagciymm.com/hizmetler/denetim-hizmetleri"
        />
      </Helmet>
      <h1>Denetim Hizmetleri</h1>
      <div>
        <p>
          Şirketinizin mali yapısına ilişkin olarak objektif bir şekilde
          gerçekleştirilen dış denetim ile mali mevzuatın sağlayacağı vergisel
          avantajlardan tam olarak yararlanılması, potansiyel vergi
          incelemelerine karşı riskin ortandan kaldırılması veya azaltılması,
          öngörülemeyen ve gözden kaçırılan hataların tespiti ile ileride
          yaşanacak büyük sorunların engellenmesi sağlanmaktadır.{" "}
        </p>
      </div>
    </div>
  );
};

export default DenetimHizmetleri;
