import { Helmet } from "react-helmet";
import IletisimFormu from "../Components/Iletisim/IletisimFormu";

const Iletisim = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>İletişim - Bağcı Yeminli Mali Müşavirlik </title>
        <meta
          name="description"
          content="Kayseri Yeminli Mali Müşavirlik - Bize ulaşmak için iletişim bilgileri ve formu. Sorularınız için bize yazın, size en kısa sürede dönüş yapalım !"
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link rel="canonical" href="https://www.bagciymm.com/iletisim" />
      </Helmet>
      <IletisimFormu />
    </div>
  );
};

export default Iletisim;
