import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Sayfa Bulunamadı</title>
      </Helmet>
      <p className="h1 text-center mt-5">ARADIĞINIZ SAYFA MEVCUT DEĞİL.</p>
      <p className="text-center ">
        {" "}
        <Link className="text-center  " to="/">
          Anasayfaya dönmek için tıklayın.
        </Link>
      </p>
    </div>
  );
};

export default NoPage;
