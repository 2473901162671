import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

const Duyurular = () => {
  return (
    <>
      <Helmet>
        <title>Duyurular - Bağcı Yeminli Mali Müşavirlik</title>
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link
          rel="canonical"
          href="https://www.bagciymm.com/duyurular/idc3fd9d5760bf3"
        ></link>
      </Helmet>
      <Outlet />
    </>
  );
};

export default Duyurular;
