import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth, databaseRef } from "../firebase/firebase";
import { child, remove, set } from "firebase/database";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

const initialState = {
  loading: false,
  error: "",
  currentPage: "duyurular",
  adminCredentials: null,
};

export const putArac = createAsyncThunk("putArac", async (arac) => {
  let response = await set(child(databaseRef, `/araclar/${arac.id}`), {
    title: arac.title.trim(),
    url: arac.url.trim(),
    id: arac.id,
  });

  return response;
});

export const addArac = createAsyncThunk("addArac", async (arac) => {
  const id = `${"id" + Math.random().toString(16).slice(2)}`;
  let response = await set(child(databaseRef, `/araclar/${id}`), {
    id: id,
    title: arac.title.trim(),
    url: arac.url.trim(),
  });

  return response;
});
export const deleteArac = createAsyncThunk("deleteArac", async (info) => {
  let response = remove(child(databaseRef, `/araclar/${info.selected}`));

  return response;
});
export const putSoru = createAsyncThunk("putSoru", async (soru) => {
  let response = await set(child(databaseRef, `/sorular/${soru.id}`), {
    answer: soru.answer,
    title: soru.title.trim(),
    id: soru.id,
  });

  return response;
});

export const addSoru = createAsyncThunk("addSoru", async (soru) => {
  const id = `${"id" + Math.random().toString(16).slice(2)}`;
  let response = await set(child(databaseRef, `/sorular/${id}`), {
    id: id,
    answer: soru.answer,
    title: soru.title.trim(),
  });

  return response;
});
export const deleteSoru = createAsyncThunk("deleteSoru", async (info) => {
  let response = remove(child(databaseRef, `/sorular/${info.selected}`));

  return response;
});
export const putHaber = createAsyncThunk("putHaber", async (haber) => {
  let response = await set(child(databaseRef, `/haberler/${haber.id}`), {
    body: haber.body,
    title: haber.title.trim(),
    id: haber.id,
    date: haber.date,
  });

  return response;
});

export const addHaber = createAsyncThunk("addHaber", async (haber) => {
  const id = `${"id" + Math.random().toString(16).slice(2)}`;
  var date = new Date();
  let response = await set(child(databaseRef, `/haberler/${id}`), {
    id: id,
    body: haber.body,
    title: haber.title.trim(),
    date: date.toLocaleDateString(),
  });

  return response;
});
export const deleteHaber = createAsyncThunk("deleteHaber", async (info) => {
  let response = remove(child(databaseRef, `/haberler/${info.selected}`));

  return response;
});
export const putDuyuru = createAsyncThunk("putDuyuru", async (duyuru) => {
  let response = await set(child(databaseRef, `/duyurular/${duyuru.id}`), {
    body: duyuru.body,
    title: duyuru.title.trim(),
    id: duyuru.id,
    date: duyuru.date,
  });

  return response;
});

export const addDuyuru = createAsyncThunk("addDuyuru", async (duyuru) => {
  const id = `${"id" + Math.random().toString(16).slice(2)}`;
  var date = new Date();
  let response = await set(child(databaseRef, `/duyurular/${id}`), {
    id: id,
    body: duyuru.body,
    title: duyuru.title.trim(),
    date: date.toLocaleDateString(),
  });

  return response;
});
export const deleteDuyuru = createAsyncThunk("deleteDuyuru", async (info) => {
  let response = remove(child(databaseRef, `/duyurular/${info.selected}`));

  return response;
});
export const authAdmin = createAsyncThunk("authAdmin", (adminCredentials) => {
  const response = signInWithEmailAndPassword(
    auth,
    adminCredentials.email,
    adminCredentials.password
  )
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
    });

  return response;
});
export const adminLogout = createAsyncThunk("adminLogout", () => {
  const response = signOut(auth);

  return response;
});

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setAdminCredentials(state, action) {
      state.adminCredentials = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authAdmin.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(authAdmin.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(authAdmin.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteDuyuru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDuyuru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteDuyuru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addDuyuru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addDuyuru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addDuyuru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(putDuyuru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(putDuyuru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putDuyuru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteHaber.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHaber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteHaber.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addHaber.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addHaber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addHaber.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(putHaber.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(putHaber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putHaber.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteSoru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSoru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteSoru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addSoru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addSoru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addSoru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(putSoru.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(putSoru.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putSoru.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteArac.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteArac.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteArac.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addArac.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addArac.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addArac.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(putArac.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(putArac.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putArac.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default adminSlice;
export const adminActions = adminSlice.actions;
