import { Helmet } from "react-helmet";
import MisyonumuzContainer from "../../Components/Misyonumuz/MisyonumuzContainer";

const Misyonumuz = () => {
  return (
    <div>
      <Helmet>
        <title>Misyonumuz - Bağcı Yeminli Mali Müşavirlik </title>
        <meta
          name="description"
          content="Firmamızın misyonu, Finansal mükemmeliği sağlayarak müşterilere güvenilir, profesyonel ve yenilikçi mali çözümler sunmaktır."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link
          rel="canonical"
          href="https://www.bagciymm.com/misyonumuz"
        />
      </Helmet>
      <MisyonumuzContainer />
    </div>
  );
};

export default Misyonumuz;
