import { Link } from "react-router-dom";
import styles from "./BizKimiz.module.css";
const BizKimiz = () => {
  return (
    <div
      className={`${styles.vizyonumuzContainer} mb-5 mt-3  container-xl px-5`}
    >
      <p className={styles.heading}>Biz Kimiz</p>
      <hr />
      <div className="mt-5   ">
        <div className="">
          <div className={styles.imgSpan} style={{}}>
            <img
              className={styles.bagciImg}
              src="images/ismail_bagci.webp"
              alt="İsmail Bağcı - Bağcı Yeminli Mali Müşavirlik"
            />
            <Link
              target="_blank"
              className={styles.linkedinImg}
              to="https://www.linkedin.com/in/i-bagci"
            >
              <img src="/icons/linkedin.svg" alt="Linkedin - İsmail Bağcı " />
            </Link>
          </div>

          <h1 className="h1">İsmail Bağcı (Kurucu)</h1>
          <h2 className="h4">Yeminli Mali Müşavir - Bağımsız Denetçi</h2>
          <br />
          <br />
          <p>
            12.05.1984 yılında Kayseri’de doğmuştur. İlk, Orta Okulu ve Liseyi
            Kayseri’de tamamlamıştır. 2009 yılında Uludağ Üniversitesi İİBF
            Uluslararası İlişkiler Bölümünden mezun olmuştur. Üniversite
            öğrenimi sırasında Ankara Üniversitesi Tömer Dil Kursunun 12 kurunu
            tamamlayarak İngilizce Dili için B2 düzeyi dil sertifikasını almaya
            hak kazanmıştır. 2010-2013 yılı arasında Maliye Bakanlığında Milli
            Emlak Denetmen Yardımcısı olarak görev yapmıştır. 2013 yılında Vergi
            Müfettiş Yardımcısı olarak göreve başlamıştır. 2016 yılında
            yeterlilik sınavında başarılı olarak Vergi Müfettişi olmaya hak
            kazanmıştır. Kayseri, Konya ve Ankara’daki Başkanlıklarda Vergi
            Müfettişi olarak görev yapmış olup aynı zamanda mezkur illerin
            yanında Türkiye’nin çeşitli kentlerinde de Vergi incelemesi
            görevlerinde bulunmuştur. 2019 yılında Yeminli Mali Müşavirlik
            Sınavının 10 dersini vererek YMM olmaya hak kazanmıştır. Hazine ve
            Maliye Bakanlığı Ankara Vergi Kaçakçılığı-2 Denetim Daire
            Başkanlığındaki Vergi Müfettişliği görevinden 14.10.2023 tarihinde
            istifa ederek Yeminli Mali Müşavir olarak faaliyet göstermeye
            başlamıştır. İsmail BAĞCI, Vergi müfettişliği görevinde iken
            edindiği bilgi ve tecrübeleri kullanarak müşterilerine etkin, etkili
            ve verimli bir yapı altında doğru, kaliteli ve mevzuatın bütün
            gereklerine uygun hizmeti sunmayı amaç edinen Bağcı Yeminli Mali
            Müşavirlik şahıs firmasını kurmuştur. Yeminli Mali Müşavirimiz aynı
            zamanda Kamu Gözetim Kurumu tarafından da “Bağımsız Denetçi” olarak
            da yetkilendirilmiş olup, evli ve bir çocuk babasıdır.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BizKimiz;
