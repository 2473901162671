import { Helmet } from "react-helmet";

const EgitimHizmetleri = () => {
  return (
    <div>
      <Helmet>
        <title>Eğitim Hizmetleri - Bağcı Yeminli Mali Müşavirlik </title>
        <meta
          name="description"
          content="Vergi sistemi ve mevzuatının karmaşıklığını anlamak ve hatalardan kaçınmak için eğitim hizmetlerimizden yararlanın. Vergi uygulamalarına yönelik bilgi ve tecrübenizi artırmak için eğitim seminerlerimize katılın."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link
          rel="canonical"
          href="https://www.bagciymm.com/hizmetler/egitim-hizmetleri"
        />
      </Helmet>
      <h1>Eğitim Hizmetleri</h1>
      <div>
        <p>
          Türk vergi sistemi gün geçtikçe karmaşık ve anlaşılması zor hale
          gelmektedir. Bu durum vergi uygulamaları açısından hatalara, bu
          hatalar ise mükelleflere hem ekonomik hem de ticari hayatta
          müşterilerine karşı zorluklar ortaya çıkarabilmektedir. Bu durumun
          önüne geçmek için işletmelerin finans ve mali işlemlerini yürüten
          personellerinin vergi mevzuatı açısından bilgi ve tecrübesini
          artırabilmek adına talep edilmesi durumunda istenilen konularda eğitim
          seminerleri hizmeti sunmaktayız.{" "}
        </p>
      </div>
    </div>
  );
};

export default EgitimHizmetleri;
