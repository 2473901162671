import { useState } from "react";
import styles from "./SectionSix.module.css";
import { Email } from "../../../smtp/smtp";
const SectionSix = () => {
  const [userEmail, setUserEmail] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();

    Email.send({
      SecureToken: "2da4f4ca-4923-4d6a-add4-f3aa79d9cf65",
      To: "info@bagciymm.com",
      From: "emailyonlendirme@bagciymm.com",
      Subject: "bagciymm.com Bülten Aboneliği Yönlendirme",
      Body: `Abone Olmak isteyen kullanıcı emaili : ${userEmail} `,
    }).then(function (message) {
      if (message === "OK") {
        setUserEmail("");
      }
    });
  };

  return (
    <section className={styles.sectionSix}>
      <div className="container-xl d-flex flex-column align-items-center">
        <p className="text-center mb-5">
          Son Dakika Haberleri ve Bağcı Yeminli Mali Müşavirlik Hakkında En
          Güncel Bilgiler için E-Posta Bültenimize Abone Olabilirsiniz.
        </p>
        <form
          onSubmit={submitHandler}
          className={`${styles.inputGroup} input-group mb-3`}
        >
          <input
            value={userEmail}
            onChange={(e) => {
              setUserEmail(e.currentTarget.value);
            }}
            type="email"
            className="form-control"
            placeholder="info@bagciymm.com"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
          />
          <button
            className="btn btn-outline-secondary "
            type="submit"
            id="button-addon2"
          >
            Abone Ol
          </button>
        </form>
      </div>
    </section>
  );
};

export default SectionSix;
