export const HizmetlerData = [
  {
    fullTitle: "KDV İadesi Tasdik Raporu Hizmetleri ",
    homeDescription:
      "Şirketlerin KDV iadelerini alabilmelerini sağlayan, mali avantajlar sunan ve mali istikrarı artıran Yeminli Mali Müşavir hizmeti.",
    homeTitle: "KDV İadesi Tasdik Raporu ",
    id: 0,
    path: "/hizmetler/kdv-iadesi-tasdik-raporu",
  },
  {
    fullTitle: "Tam Tasdik Hizmeti ",
    homeDescription:
      "Şirketlerin finansal hesaplarını doğrulayarak hesap verebilirliklerini artıran ve mali riskleri azaltan önemli bir hizmet.",
    homeTitle: "Tam Tasdik Hizmeti ",
    id: 1,
    path: "/hizmetler/tam-tasdik",
  },
  {
    fullTitle: "Özel Amaçlı Tam Tasdik Hizmeti",
    homeDescription:
      "Belirli işlemler için finansal bilgilerin doğrulanması ve adil işlem güvencesi sağlayan kritik bir hizmet. ",
    homeTitle: "Özel Amaçlı Tam Tasdik Hizmeti",
    id: 2,
    path: "/hizmetler/ozel-amacli-tam-tasdik",
  },
  {
    fullTitle: "Gelir ve Kurumlar Vergisi İadeleri",
    homeDescription:
      "Gelir ve kurumlar vergisi iadelerinde uzmanlaşmış Yeminli Mali Müşavirler tarafından mükelleflere vergisel avantajlar sağlayan ve riskleri azaltan hizmet.",
    homeTitle: "Vergi İadeleri",
    id: 3,
    path: "/hizmetler/gelir-ve-kurumlar-vergisi-iadeleri",
  },
  {
    fullTitle: "Teşvik Mevzuatı ve Uygulamaları",
    homeDescription:
      "Teşvik mevzuatını anlayarak işletmelere en uygun teşvik alternatiflerini değerlendiren uzman danışmanlık hizmeti.",
    homeTitle: "Teşvik Mevzuatı ve Uygulamaları",
    id: 4,
    path: "/hizmetler/tesvik-mevzuati-ve-uygulamalari",
  },
  {
    fullTitle: "Vergi İncelemesi Danışmanlığı Hizmetleri",
    homeDescription:
      "Vergi incelemeleri sırasında doğru belge hazırlığı, vergi planlaması ve risk yönetimi konularında danışmanlık.",
    homeTitle: "Vergi İncelemesi Danışmanlığı",
    id: 5,
    path: "/hizmetler/vergi-incelemesi-danismanligi",
  },
  {
    fullTitle: "Eğitim Hizmetleri",
    homeDescription:
      "Türk vergi sisteminin karmaşıklığına karşı finans ve mali işlemleri yöneten personelin bilgi düzeyini artıran eğitimler.",
    homeTitle: "Eğitim Hizmetleri",
    id: 7,
    path: "/hizmetler/egitim-hizmetleri",
  },
  {
    fullTitle: "Denetim Hizmetleri",
    homeDescription:
      "Mali yapıyı değerlendirerek vergisel avantajlardan yararlanmayı sağlayan ve riskleri azaltan denetim hizmetleri.",
    homeTitle: "Denetim Hizmetleri",
    id: 8,
    path: "/hizmetler/denetim-hizmetleri",
  },
];
