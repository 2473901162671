import styles from "./SectionFive.module.css";
const SectionFive = () => {
  return (
    <section className={`${styles.sectionFive} `}>
      <div className="row pt-5 pb-5   align-items-center w-100 ">
        <div className=" d-flex align-items-center justify-content-center  col-md-6 m-auto col-lg-4  ">
          <img
            style={{ width: "100%", margin: "auto !important" }}
            src="/images/logo2.webp"
            alt="Bağcı YMM"
          />
        </div>
        <div className=" mt-5 col-md-12 col-lg-8 p-5">
          <h4>Biz Kimiz?</h4>
          <p>
            Amacımız, müşterilerimize yeminli mali müşavirlik alanındaki geniş
            deneyim ve uzmanlık ile hizmet sunmak , onlara en iyi şekilde
            yardımcı olmak ve iş süreçlerinde kesintisiz bir yardım sağlamaktır.
            Firmalarımızın yönetimlerine vergi konularında danışmanlık yaparak,
            vergisel yükümlülüklerini eksiksiz bir şekilde yerine getirmelerini
            sağlamak öncelikli hedefimizdir. Aynı zamanda, vergi avantajlarından
            maksimum düzeyde faydalanmalarını ve kesilen vergi iadelerinin en
            hızlı şekilde gerçekleştirilmesini temin etmek de görevlerimiz
            arasındadır. Bağımsız denetim ve mali müşavirlik alanındaki güncel
            düzenlemeleri yakından takip ederek, müşterilerimize her daim güncel
            ve doğru bilgi sağlamayı amaçlıyoruz. Firmamızın misyonu,
            müşterilerimizin mali süreçlerini etkin bir şekilde yönetmelerine
            destek olmak ve onlara güvenilir, kaliteli ve özverili bir hizmet
            sunmaktır.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
