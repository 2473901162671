import { Helmet } from "react-helmet";
import BizKimiz from "../../Components/BizKimiz/BizKimiz";

const BagciYMM = () => {
  return (
    <div>
      <Helmet>
        <title>
          Bağcı Yeminli Mali Müşavirlik - Kayseri Yeminli Mali Müşavirlik
          Şirketi
        </title>
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link rel="canonical" href="https://www.bagciymm.com/bagci-ymm" />
      </Helmet>
      <BizKimiz />
    </div>
  );
};

export default BagciYMM;
