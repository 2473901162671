import { Link } from "react-router-dom";
import styles from "./SectionFour.module.css";
const SectionFour = () => {
  return (
    <section className={`${styles.sectionFour} container-fluid `}>
      <div className={`row ${styles.imageRow}`}>
        <div className={`${styles.imgCol} col-2`}>
          <Link rel="nofollow" target="_blank" to="https://www.turkiye.gov.tr/">
            <img src="/images/eDevlet.webp" alt="e-devlet" />
          </Link>
        </div>
        <div className={`${styles.imgCol} col-2`}>
          <Link rel="nofollow" target="_blank" to="https://www.gib.gov.tr/">
            <img src="/images/gelirIdaresi.webp" alt="gelir idaresi" />
          </Link>
        </div>
        <div className={`${styles.imgCol} col-2`}>
          <Link rel="nofollow" target="_blank" to="https://www.kgk.gov.tr/">
            <img
              src="/images/kamuGozetimiKurumu.webp"
              alt="kamu gözetimi kurumu"
            />
          </Link>
        </div>
        <div className={`${styles.imgCol} col-2`}>
          <Link
            rel="nofollow"
            target="_blank"
            to="https://www.resmigazete.gov.tr/"
          >
            <img src="/images/resmiGazete.webp" alt="resmi gazete" />
          </Link>
        </div>
        <div className={`${styles.imgCol} col-2`}>
          <Link rel="nofollow" target="_blank" to="https://www.turmob.org.tr/">
            <img src="/images/turmob.webp" alt="turmob" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
