import { Helmet } from "react-helmet";
import { Outlet, useLocation, useParams } from "react-router-dom";

const Haberler = () => {
  const params = useParams();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{params.haber}</title>
        <meta name="description" content={params.haber}></meta>
        <link
          rel="canonical"
          href={`https://www.bagciymm.com/haberler/${params.haber}`}
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <meta
          name="twitter:title"
          content="Bağcı Yeminli Mali Müşavirlik - Haberler "
        />
        <meta name="twitter:description" content={params.haber} />
        <meta
          name="twitter:image:src"
          content="https://www.bagciymm.com/images/logo1.webp"
        />
        <meta
          property="og:url"
          content={`https://www.bagciymm.com${location.pathname}`}
        />
        <meta
          property="og:title"
          content="Bağcı Yeminli Mali Müşavirlik - Haberler"
        />
        <meta property="og:description" content={params.haber} />
        <meta
          property="og:image"
          content="https://www.bagciymm.com/images/logo1.webp"
        />
      </Helmet>
      <Outlet />;
    </>
  );
};

export default Haberler;
