import { Helmet } from "react-helmet";
import SSSContainer from "../Components/SSS/SSSContainer";

const SSS = () => {
  return (
    <div>
      <Helmet>
        <title>Sıkça Sorulan Sorular - Bağcı Yeminli Mali Müşavirlik</title>
        <meta
          name="description"
          content="Sıkça Sorulan Sorular (SSS) - Bağcı YMM hakkında yaygın sorulan soruların yanıtlarını bulun."
        />
        <meta httpEquiv="Content-Type" content="text/html" charSet="UTF8" />
        <link rel="canonical" href="https://www.bagciymm.com/sss" />
      </Helmet>
      <SSSContainer />
    </div>
  );
};

export default SSS;
