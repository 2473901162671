import { useSelector } from "react-redux";
import styles from "./Spinner.module.css";
const Spinner = () => {
  const isLoading = useSelector((state) => state.content.contentLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Spinner;
