import styles from "./HaberlerContainer.module.css";
import HaberListesi from "../HaberListesi/HaberListesi";
import HaberOutlet from "../HaberOutlet/HaberOutlet";
import { useSelector } from "react-redux";
const HaberlerContainer = () => {
  const haberler = useSelector((state) => state.content.haberler);

  return (
    <div className={`container-xl  mb-5 mt-3  ${styles.vizyonumuzContainer} `}>
      <div className={styles.banner}>
        <p className={styles.heading}>Haberler</p>
        <div className="d-flex align-items-center mb-5 justify-content-center w-50 m-auto">
          <span className="line"></span>
          <img
            style={{ width: "2.5rem", margin: "0rem 1rem" }}
            src="/icons/icon-haberler.webp"
            alt="star"
          />
          <span className="line"></span>
        </div>
      </div>

      <div className="row mt-3  ">
        <div className={`col-md-4  mb-5   col-sm-12 ${styles.duyuruListesi}`}>
          <HaberListesi haberler={haberler} />
        </div>
        <div className={`${styles.duyuruOutletContainer} col-md-8 col-sm-12`}>
          <HaberOutlet />
        </div>
      </div>
    </div>
  );
};

export default HaberlerContainer;
