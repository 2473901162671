import { Navigate, useParams } from "react-router-dom";
import styles from "./DuyuruOutlet.module.css";
import { useSelector } from "react-redux";

const DuyuruOutlet = (props) => {
  const params = useParams();
  var date = new Date();

  const duyurular = useSelector((state) => state.content.duyurular);
  let duyuru;
  if (params.id === "0") {
    duyuru = duyurular[0];
  } else {
    duyuru = duyurular.find((duyuru) => duyuru.id === params.id);
  }

  return (
    <div className={`${styles.duyuruOutlet} `}>
      {" "}
      {duyuru && (
        <>
          {" "}
          <div className={styles.duyuruOutletHeader}>
            <h2>{duyuru.title}</h2>
            <span>{duyuru.date}</span>
          </div>
          <p>{duyuru.body}</p>
        </>
      )}
      {!duyuru && <Navigate to="/duyurular/0" replace />}
    </div>
  );
};

export default DuyuruOutlet;
