import { Link } from "react-router-dom";
import styles from "./NavbarContainer.module.css";
import { useState } from "react";
import NavbarDropdowns from "../NavbarDropdowns/NavbarDropdowns";

const NavbarContainer = () => {
  const [isStickyNavbar, setIsStıckyNavbar] = useState(false);
  window.onscroll = () => {
    if (window.scrollY <= document.getElementById("topNavbar").offsetHeight) {
      setIsStıckyNavbar(false);
      document.getElementById("navbar").style.top = `${
        document.getElementById("topNavbar").offsetHeight - window.scrollY
      }px`;
    } else {
      setIsStıckyNavbar(true);
    }
  };

  document.addEventListener("click", (e) => {
    closeNavbar();
  });
  const closeNavbar = () => {
    document.getElementById("navbarNavDropdown").classList.add("collapse");
    document.getElementById("navbarNavDropdown").classList.remove("show");
    document
      .getElementById("mobileNavbarHamburgerIcon")
      .setAttribute("aria-expanded", "false");
  };
  return (
    <>
      <nav
        id="mobileNavbarContainerDocument"
        className={`${styles.mobileNavbarContainer} navbar mb-3 sticky-top navbar-expand-lg navbar-light bg-light `}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src="/images/logo1.webp"
              height="50"
              alt="Bağcı Yeminli Mali Müşavirlik Kayseri"
            />
          </Link>
          <button
            onClick={(e) => {
              e.stopPropagation();
            }}
            id="mobileNavbarHamburgerIcon"
            className={`${styles.hamburgerIcon} navbar-toggler`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></button>
          <div
            className={`${styles.mobileNavbar} collapse mt-3 navbar-collapse `}
            id="navbarNavDropdown"
          >
            <ul className={`${styles.mobileNavbarList} navbar-nav `}>
              <li className={`${styles.mobileNavbarListItem} nav-item`}>
                <Link className="nav-link active" aria-current="page" to="/">
                  Anasayfa
                </Link>
              </li>
              <li
                className={`${styles.mobileNavbarListItemDropdown} nav-item dropdown `}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`${styles.mobileNavbarListItemDropdownToggleButton} nav-link dropdown-toggle`}
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Kurumsal
                </button>
                <ul
                  className={`${styles.mobileNavbarListItemDropdownMenu} dropdown-menu`}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/bagci-ymm">
                      Bağcı YMM
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/misyonumuz">
                      Misyonumuz
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/vizyonumuz">
                      Vizyonumuz
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${styles.mobileNavbarListItemDropdown} nav-item dropdown`}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`${styles.mobileNavbarListItemDropdownToggleButton} nav-link dropdown-toggle`}
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Hizmetlerimiz
                </button>
                <ul
                  className={`${styles.mobileNavbarListItemDropdownMenu} dropdown-menu`}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/kdv-iadesi-tasdik-raporu"
                    >
                      KDV İadesi Tasdik Raporu Hizmetleri
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/hizmetler/tam-tasdik">
                      Tam Tasdik Hizmeti
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/ozel-amacli-tam-tasdik"
                    >
                      Özel Amaçlı Tam Tasdik Hizmeti
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/gelir-ve-kurumlar-vergisi-iadeleri"
                    >
                      Gelir ve Kurumlar Vergisi İadeleri
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/tesvik-mevzuati-ve-uygulamaları"
                    >
                      Teşvik Mevzuatı ve Uygulamaları
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/vergi-incelemesi-danismanligi"
                    >
                      Vergi İncelemesi Danışmanlığı Hizmetleri
                    </Link>
                  </li>

                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/egitim-hizmetleri"
                    >
                      Eğitim Hizmetleri
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="/hizmetler/denetim-hizmetleri"
                    >
                      Denetim Hizmetleri
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${styles.mobileNavbarListItemDropdown} nav-item dropdown`}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`${styles.mobileNavbarListItemDropdownToggleButton} nav-link dropdown-toggle`}
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Daha Fazla
                </button>
                <ul
                  className={`${styles.mobileNavbarListItemDropdownMenu} dropdown-menu`}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/duyurular">
                      Duyurular
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} `}
                  >
                    <Link className="dropdown-item" to="/haberler">
                      Haberler
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${styles.mobileNavbarListItemDropdown} nav-item dropdown`}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`${styles.mobileNavbarListItemDropdownToggleButton} nav-link dropdown-toggle`}
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Araçlar
                </button>
                <ul
                  className={`${styles.mobileNavbarListItemDropdownMenu} dropdown-menu`}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} ${styles.toolsList} `}
                  >
                    <Link
                      className="dropdown-item"
                      to="https://www.turmob.org.tr/hesaplamalar/mtv"
                      rel="nofollow"
                      target="_blank"
                    >
                      MTV Hesaplama - TURMOB
                    </Link>
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} ${styles.toolsList} `}
                  >
                    <Link
                      className="dropdown-item"
                      rel="nofollow"
                      target="_blank"
                      to="https://www.turmob.org.tr/hesaplamalar/gelirvergisi"
                    >
                      Gelir Vergisi Hesaplama - TURMOB
                    </Link>{" "}
                  </li>
                  <li
                    className={`${styles.mobileNavbarListItemDropdownMenuItem} ${styles.toolsList} `}
                  >
                    <Link
                      className="dropdown-item"
                      rel="nofollow"
                      target="_blank"
                      to="https://www.turmob.org.tr/hesaplamalar/KDV-Tevkifat-Hesaplama"
                    >
                      KDV Tevkifat Hesaplama - TURMOB
                    </Link>{" "}
                  </li>
                </ul>
              </li>{" "}
              <li className={`${styles.mobileNavbarContactUsButton} nav-item `}>
                <Link to="/iletisim">Bize Ulaşın</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav
        className={`${styles.navbarContainer} ${
          isStickyNavbar && styles.noShadow
        }`}
      >
        <div id="topNavbar" className={styles.topNav}>
          <Link
            target="_blank"
            to="https://twitter.com/bagciymm"
            className={styles.twitterLogo}
          >
            {" "}
            <img
              src="/icons/twitter.svg"
              alt="Twitter - Bağcı Yeminli Mali Müşavirlik "
            />
          </Link>{" "}
          <Link
            target="_blank"
            to="https://www.instagram.com/bagciymm"
            className={styles.instagramLogo}
          >
            {" "}
            <img
              src="/icons/instagram.png"
              alt="İnstagram - Bağcı Yeminli Mali Müşavirlik "
            />
          </Link>
          <Link
            target="_blank"
            to="https://www.facebook.com/profile.php?id=61552560837312"
            className={styles.facebookLogo}
          >
            {" "}
            <img
              src="/icons/facebook.png"
              alt="Facebook - Bağcı Yeminli Mali Müşavirlik "
            />
          </Link>
        </div>

        <div
          id="navbar"
          className={`${styles.navbar} ${
            isStickyNavbar && styles.stickyNavbar
          }`}
        >
          <div>
            <Link to="/">
              {" "}
              <img
                style={{ height: "6rem" }}
                src="/images/logo1.webp"
                alt="Bağcı YMM Kayseri"
              />
            </Link>
          </div>

          <NavbarDropdowns />

          <Link className={styles.contactLink} to="/iletisim">
            Bize Ulaşın
          </Link>
        </div>
      </nav>
    </>
  );
};

export default NavbarContainer;
