import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { databaseRef } from "../firebase/firebase";
import { child, get } from "firebase/database";

const initialState = {
  contentLoading: true,
  duyurular: [],
  haberler: [],

  sorular: [],
  araclar: [],
};

export const fetchContent = createAsyncThunk("fetchContent", async () => {
  let response = await get(child(databaseRef, `/`))
    .then((snapshot) => snapshot.val())
    .catch((error) => {
      alert(error);
    });
  return response;
});

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.contentLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContent.pending, (state, action) => {
      state.contentLoading = true;
      state.error = "";
    });
    builder.addCase(fetchContent.fulfilled, (state, action) => {
      state.contentLoading = false;
      state.duyurular = Object.values(action.payload.duyurular);
      state.haberler = Object.values(action.payload.haberler);
      state.sorular = Object.values(action.payload.sorular);
      state.araclar = Object.values(action.payload.araclar);
    });
    builder.addCase(fetchContent.rejected, (state, action) => {
      state.contentLoading = false;
      state.error = action.error.message;
    });
  },
});

export default contentSlice;
export const contentActions = contentSlice.actions;
