// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBRHTR_CmyqNXf0ebn13Mcxys_zjFiJ-Lc",
  authDomain: "bagciymm.firebaseapp.com",
  databaseURL: "https://bagciymm-default-rtdb.firebaseio.com",
  projectId: "bagciymm",
  storageBucket: "bagciymm.appspot.com",
  messagingSenderId: "606809970944",
  appId: "1:606809970944:web:861b05e42a5aa239e6bbe5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
const database = getDatabase(app);
export const databaseRef = ref(database, "/");
export default app;
