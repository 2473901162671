import styles from "./DuyurularContainer.module.css";
import DuyuruListesi from "../DuyuruListesi/DuyuruListesi";
import DuyuruOutlet from "../DuyuruOutlet/DuyuruOutlet";
import { useSelector } from "react-redux";
const DuyurularContainer = () => {
  const duyurular = useSelector((state) => state.content.duyurular);

  return (
    <div className={`container-xl  mb-5 mt-3  ${styles.servicesContainer} `}>
      <div className={styles.banner}>
        <p className={styles.heading}>Duyurular</p>
        <div className="d-flex align-items-center mb-5 justify-content-center w-50 m-auto">
          <span className="line"></span>
          <img
            style={{ width: "2.5rem", margin: "0rem 1rem" }}
            src="/icons/icon-duyuru.webp"
            alt="star"
          />
          <span className="line"></span>
        </div>
      </div>

      <div className="row mt-3  ">
        <div className={`col-md-4  mb-5   col-sm-12 ${styles.duyuruListesi}`}>
          <DuyuruListesi duyurular={duyurular} />
        </div>
        <div className={`${styles.duyuruOutletContainer} col-md-8 col-sm-12`}>
          <DuyuruOutlet />
        </div>
      </div>
    </div>
  );
};

export default DuyurularContainer;
