import { useState } from "react";
import styles from "./SectionThree.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const SectionThree = () => {
  const [openPanel, setOpenPanel] = useState(1);
  const duyurular = useSelector((state) => state.content.duyurular);
  const haberler = useSelector((state) => state.content.haberler);
  const araclar = useSelector((state) => state.content.araclar);
  const panelController = (panelNumber) => {
    setOpenPanel(panelNumber);
  };

  return (
    <section className={`${styles.sectionThree} container-sm`}>
      <ul className={styles.navTabs}>
        <li
          onClick={() => {
            panelController(1);
          }}
          className={`${openPanel === 1 && styles.activeButton}`}
        >
          Duyurular
        </li>
        <li
          className={`${openPanel === 2 && styles.activeButton}`}
          onClick={() => {
            panelController(2);
          }}
        >
          Haberler
        </li>
        <li
          className={`${openPanel === 3 && styles.activeButton}`}
          onClick={() => {
            panelController(3);
          }}
        >
          Araçlar
        </li>
      </ul>
      <div
        className={`${styles.panelContainer} ${
          openPanel === 1 && styles.noBorderLeft
        } ${openPanel === 3 && styles.noBorderRightMobile}`}
      >
        <div
          className={`${styles.tabPanel} ${
            openPanel === 1 && styles.activePanel
          }`}
        >
          <ul>
            {duyurular.slice(0, 5).map((duyuru) => (
              <Link key={duyuru.id} to={`/duyurular/${duyuru.id}`}>
                <li>
                  <p>{duyuru.title}</p>
                </li>
              </Link>
            ))}
            <Link to="/duyurular">
              <li style={{ listStyleType: "none" }}>&gt;&gt; Daha Fazla</li>
            </Link>
          </ul>
        </div>
        <div
          className={`${styles.tabPanel} ${
            openPanel === 2 && styles.activePanel
          }`}
        >
          <ul>
            {haberler.slice(0, 5).map((haber) => (
              <Link key={haber.id} to={`/haberler/${haber.title}`}>
                <li>
                  <p>{haber.title}</p>
                </li>
              </Link>
            ))}
            <Link to="/haberler">
              <li style={{ listStyleType: "none" }}>&gt;&gt; Daha Fazla</li>
            </Link>
          </ul>
        </div>
        <div
          className={`${styles.tabPanel} ${
            openPanel === 3 && styles.activePanel
          }`}
        >
          <ul>
            {araclar.slice(0, 5).map((arac) => (
              <Link key={arac.id} rel="nofollow" target="_blank" to={arac.url}>
                <li>
                  <p>{arac.title}</p>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
