import { Outlet } from "react-router-dom";
import ServicesContainer from "../Components/Hizmetlerimiz/ServicesContainer/ServicesContainer";

const Hizmetlerimiz = () => {
  return (
    <>
      <ServicesContainer>
        <Outlet />
      </ServicesContainer>
    </>
  );
};

export default Hizmetlerimiz;
