import { Link, useLocation } from "react-router-dom";
import styles from "./ServicesContainer.module.css";
import { HizmetlerData } from "../../../Data/Data";

const ServicesContainer = (props) => {
  const location = useLocation();
  const hizmetler = HizmetlerData;

  return (
    <div className={`container-xl  mb-5  mt-3 ${styles.servicesContainer} `}>
      <div className={styles.banner}>
        <p className={styles.heading}>Hizmetlerimiz</p>
        <div className="d-flex align-items-center mb-5 justify-content-center w-50 m-auto">
          <span className="line"></span>
          <img
            style={{ width: "1.5rem" }}
            src="/icons/icon-star.webp"
            alt="star"
          />
          <span className="line"></span>
        </div>
      </div>
      <div className="row mt-5 ">
        <div className={`col-md-4  mb-5   col-sm-12 ${styles.servicesList}`}>
          <div className="list-group global-bootstraplistGroup ">
            {hizmetler.map((hizmet) => {
              return (
                <Link
                  key={hizmet.id}
                  aria-current="true"
                  className={`list-group-item list-group-item-action ${
                    location.pathname === hizmet.path && "active"
                  }`}
                  to={hizmet.path}
                >
                  {hizmet.fullTitle}
                </Link>
              );
            })}
          </div>
        </div>
        <div className={`${styles.serviceOutlet} col-md-8 col-sm-12`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ServicesContainer;
