/* eslint-disable no-undef */
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Anasayfa from "./Pages/Anasayfa";
import NoPage from "./Pages/NoPage";
import Layout from "./Pages/Layout";
import Iletisim from "./Pages/Iletisim";
import TamTasdik from "./Pages/Hizmetler/TamTasdik";
import Hizmetlerimiz from "./Pages/Hizmetlerimiz";
import KdvIadesiTasdikRaporu from "./Pages/Hizmetler/KdvIadesiTasdikRaporu";
import OzelAmacliTamTasdik from "./Pages/Hizmetler/OzelAmacliTamTasdik";
import GelirVeKurumlarVergisiIadeleri from "./Pages/Hizmetler/GelirVeKurumlarVergisiIadeleri";
import VergiIncelemesiDanismanligi from "./Pages/Hizmetler/VergiIncelemesiDanismanligi";
import EgitimHizmetleri from "./Pages/Hizmetler/EgitimHizmetleri";
import DenetimHizmetleri from "./Pages/Hizmetler/DenetimHizmetleri";
import TesvikMevzuatiVeUygulamalari from "./Pages/Hizmetler/TesvikMevzuatiVeUygulamalari";
import React, { useEffect, useState } from "react";
import BagciYMM from "./Pages/Kurumsal/BagciYMM";
import Misyonumuz from "./Pages/Kurumsal/Misyonumuz";
import Vizyonumuz from "./Pages/Kurumsal/Vizyonumuz";
import Duyurular from "./Pages/Duyurular";
import Haberler from "./Pages/Haberler";

import ScrollToTop from "./Components/Global/ScrollToTop";
import DuyurularContainer from "./Components/Duyurular/DuyurularContainer/DuyurularContainer";
import HaberlerContainer from "./Components/Haberler/HaberlerContainer/HaberlerContainer";
import SSS from "./Pages/SSS";
import Spinner from "./Components/Global/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchContent } from "./Redux/content";
const AdminLoginPanel = React.lazy(() => import("./Pages/AdminLoginPanel"));

function App() {
  const [isShowTopButton, setIsShowTopButton] = useState(false);
  const contentLoading = useSelector((state) => state.content.contentLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContent());
  }, []);

  window.addEventListener("scroll", () => {
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      setIsShowTopButton(true);
    } else {
      setIsShowTopButton(false);
    }
  });

  return (
    <>
      <Spinner />
      {!contentLoading && (
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/adminpanel"
              element={
                <React.Suspense fallback={<div>Yükleniyor..</div>}>
                  <AdminLoginPanel />
                </React.Suspense>
              }
            />

            <Route path="" element={<Layout />}>
              <Route index element={<Anasayfa />} />
              <Route path="/iletisim" element={<Iletisim />} />
              <Route path="/duyurular" element={<Duyurular />}>
                <Route path="/duyurular/:id" element={<DuyurularContainer />} />
                <Route
                  path=""
                  element={<Navigate to="/duyurular/0" replace />}
                />
              </Route>

              <Route path="/haberler" element={<Haberler />}>
                <Route index element={<HaberlerContainer />} />
                <Route
                  path="/haberler/:haber"
                  element={<HaberlerContainer />}
                />

                <Route path="/haberler/*" element={<NoPage />} />
              </Route>

              <Route path="/hizmetler" element={<Hizmetlerimiz />}>
                <Route index element={<KdvIadesiTasdikRaporu />} />
                <Route
                  path="/hizmetler/kdv-iadesi-tasdik-raporu"
                  element={<KdvIadesiTasdikRaporu />}
                />
                <Route path="/hizmetler/tam-tasdik" element={<TamTasdik />} />
                <Route
                  path="/hizmetler/ozel-amacli-tam-tasdik"
                  element={<OzelAmacliTamTasdik />}
                />
                <Route
                  path="/hizmetler/gelir-ve-kurumlar-vergisi-iadeleri"
                  element={<GelirVeKurumlarVergisiIadeleri />}
                />

                <Route
                  path="/hizmetler/vergi-incelemesi-danismanligi"
                  element={<VergiIncelemesiDanismanligi />}
                />
                <Route
                  path="/hizmetler/egitim-hizmetleri"
                  element={<EgitimHizmetleri />}
                />
                <Route
                  path="/hizmetler/denetim-hizmetleri"
                  element={<DenetimHizmetleri />}
                />
                <Route
                  path="/hizmetler/tesvik-mevzuati-ve-uygulamalari"
                  element={<TesvikMevzuatiVeUygulamalari />}
                />
                <Route
                  path="/hizmetler/*"
                  element={
                    <Navigate
                      to="/hizmetler/kdv-iadesi-tasdik-raporu"
                      replace
                    />
                  }
                  OzelAmacliTamTasdik
                />
              </Route>

              <Route path="/bagci-ymm" element={<BagciYMM />} />
              <Route path="/vizyonumuz" element={<Vizyonumuz />} />
              <Route path="/misyonumuz" element={<Misyonumuz />} />
              <Route path="/sss" element={<SSS />} />

              <Route path="/*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
      <button
        type="button"
        className={`btn btn-danger btn-floating  btn-lg ${
          isShowTopButton ? "d-block" : "d-none"
        } `}
        id="btn-back-to-top"
        onClick={() => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }}
      >
        &gt;
      </button>
    </>
  );
}

export default App;
