import styles from "./HaberListesi.module.css";
import { Link, useParams } from "react-router-dom";

const HaberListesi = (props) => {
  const params = useParams();

  return (
    <div
      className={`${styles.duyuruListesi} list-group global-bootstraplistGroup`}
    >
      {props.haberler.map((haber) => (
        <Link
          key={haber.id}
          to={`/haberler/${haber.title}`}
          aria-current="true"
          className={`list-group-item list-group-item-action ${
            haber.title === params.haber && "active"
          }`}
        >
          {haber.title}
        </Link>
      ))}
    </div>
  );
};

export default HaberListesi;
