import { useState } from "react";
import styles from "./IletisimFormu.module.css";
import { Email } from "../../smtp/smtp";
const IletisimFormu = () => {
  const [client, setClient] = useState({
    isim: "",
    telefon: "",
    eposta: "",
    konu: "",
    mesaj: "",
  });

  const changeHandler = (e) => {
    switch (e.currentTarget.id) {
      case "nameInput":
        setClient({ ...client, isim: e.currentTarget.value });
        break;
      case "phoneInput":
        setClient({ ...client, telefon: e.currentTarget.value });
        break;
      case "emailInput":
        setClient({ ...client, eposta: e.currentTarget.value });
        break;
      case "subjectInput":
        setClient({ ...client, konu: e.currentTarget.value });
        break;
      case "msgInput":
        setClient({ ...client, mesaj: e.currentTarget.value });
        break;
      default:
        break;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    Email.send({
      SecureToken: "2da4f4ca-4923-4d6a-add4-f3aa79d9cf65",
      To: "info@bagciymm.com",
      From: "emailyonlendirme@bagciymm.com",
      Subject: "bagciymm.com İletişim Formu Yönlendirme",
      Body: `
        İsim: ${client.isim},<br/>
        Telefon:${client.telefon},<br/>
        E-posta adresi:${client.eposta},<br/>
        Konu:${client.konu},<br/>
        Mesaj:${client.mesaj}
        `,
    }).then(function (message) {
      if (message === "OK") {
        setClient({
          isim: "",
          telefon: "",
          eposta: "",
          konu: "",
          mesaj: "",
        });
      }
    });
  };

  return (
    <form
      onSubmit={submitHandler}
      id="captchaForm"
      aria-describedby="emailHelp"
      className={`${styles.contactForm} mt-5 mb-5 container-fluid row captchaForm`}
    >
      <div className="infoColumn  col-md-5 d-flex flex-column container-fluid">
        <div className="infoColumnBoxes d-flex w-50 m-auto mt-3 align-items-start">
          <img src="/icons/icon-location.webp" alt="Kayseri Ofis" />
          <div className="d-flex flex-column text-start">
            <h5 className="text-start">Adres</h5>
            <span>
              Hacı Saki Mah. Çimen Sok. A Plus İşhanı No:7/27 Kocasinan/Kayseri
            </span>
          </div>
        </div>
        <div className="infoColumnBoxes d-flex w-50 m-auto mt-3">
          <img alt="Telefon" src="/icons/icon-phone.webp" />
          <div className="d-flex flex-column text-start">
            <h5 className="text-start">Telefon</h5>
            <span>0352 504 5626</span>
          </div>
        </div>
        <div className="infoColumnBoxes d-flex w-50 m-auto mt-3">
          <img src="/icons/icon-mail.webp" alt="mail" />
          <div className="d-flex flex-column text-start">
            <h5 className="text-start">Bilgi Almak için</h5>
            <span>info@bagciymm.com</span>
          </div>
        </div>
      </div>
      <div className="col-md-7">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="nameInput" className="form-label">
              Ad - Soyad
            </label>
            <input
              value={client.isim}
              onChange={changeHandler}
              required
              type="text"
              className="form-control"
              id="nameInput"
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="phoneInput" className="form-label">
              Telefon
            </label>
            <input
              value={client.telefon}
              onChange={changeHandler}
              required
              type="text"
              className="form-control"
              id="phoneInput"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">
            E-posta Adresi
          </label>
          <input
            value={client.eposta}
            onChange={changeHandler}
            required
            type="email"
            className="form-control"
            id="emailInput"
            aria-describedby="emailHelp"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subjectInput" className="form-label">
            Konu
          </label>
          <input
            value={client.konu}
            onChange={changeHandler}
            required
            type="text"
            className="form-control"
            id="subjectInput"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="textInput" className="form-label">
            Mesaj
          </label>
          <textarea
            value={client.mesaj}
            onChange={changeHandler}
            required
            rows="4"
            resize="false"
            type="textarea"
            className="form-control"
            id="msgInput"
          />
          <ul id="emailHelp" className="list-unstyled form-text">
            <li>*Bilgilerinizi hiç kimseyle paylaşmayacağız.</li>
            <li>
              *En iyi hizmeti sunabilmemiz için bilgileri eksiksiz girdiğinizden
              emin olun.
            </li>
          </ul>

          <button className={` mt-3 btn  ${styles.sendButton}`} type="submit">
            Gönder
          </button>
        </div>
      </div>
    </form>
  );
};

export default IletisimFormu;
