import styles from "./DuyuruListesi.module.css";
import { Link, useParams } from "react-router-dom";

const DuyuruListesi = (props) => {
  const params = useParams();
  const activeId =
    Number.parseInt(params.id) !== 0 ? params.id : props.duyurular[0].id;

  return (
    <div
      className={`${styles.duyuruListesi} list-group global-bootstraplistGroup`}
    >
      {props.duyurular.map((duyuru) => (
        <Link
          key={duyuru.id}
          to={`/duyurular/${duyuru.id}`}
          aria-current="true"
          className={`list-group-item list-group-item-action   ${
            activeId === duyuru.id && "active"
          }
          `}
        >
          {duyuru.title}
        </Link>
      ))}
    </div>
  );
};

export default DuyuruListesi;
