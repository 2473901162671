import { Navigate, useParams } from "react-router-dom";
import styles from "./HaberOutlet.module.css";
import { useSelector } from "react-redux";
const HaberOutlet = (props) => {
  const params = useParams();

  const haberler = useSelector((state) => state.content.haberler);
  const haber = haberler.find((haber) => haber.title === params.haber);

  return (
    <div className={`${styles.duyuruOutlet} `}>
      {" "}
      {haber && (
        <>
          {" "}
          <div className={styles.haberOutletHeader}>
            <h2>{haber.title}</h2>
            <span>{haber.date}</span>
          </div>
          <p>{haber.body}</p>
        </>
      )}
      {!haber && <Navigate to={`/haberler/${haberler[0].title}`} replace />}
    </div>
  );
};

export default HaberOutlet;
