import { useState } from "react";
import styles from "./NavbarDropdowns.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HizmetlerData } from "../../../../Data/Data";
const NavbarDropdowns = () => {
  const araclar = useSelector((state) => state.content.araclar);
  const hizmetler = HizmetlerData;
  const [openDropdown, setOpenDropdown] = useState(0);
  const [isExpandedTools, setIsExpandedTools] = useState(false);
  const [isShowLinkImg, setIsShowLınkImg] = useState(0);
  return (
    <div className={styles.navbarDropdowns}>
      <div className={styles.homeButton}>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          to="/"
          className={styles.tab}
        >
          Anasayfa
        </Link>
      </div>
      <div
        onMouseOver={() => {
          setOpenDropdown(1);
        }}
        onClick={() => {
          setOpenDropdown(1);
        }}
        onMouseLeave={() => {
          setOpenDropdown(0);
        }}
        className={` ${styles.panelContainer}`}
      >
        <div
          className={`${styles.tab} ${openDropdown === 1 && styles.activeTab} `}
        >
          {" "}
          <p>Kurumsal</p>
        </div>
        <div
          className={`${styles.panel} ${
            openDropdown === 1 && styles.openPanel
          }`}
        >
          <ul>
            <Link to="/bagci-ymm">Bağcı YMM</Link>
            <Link to="/misyonumuz"> Misyonumuz</Link>
            <Link to="/vizyonumuz">Vizyonumuz</Link>
          </ul>
        </div>
      </div>
      <div
        onMouseOver={() => {
          setOpenDropdown(2);
        }}
        onClick={() => {
          setOpenDropdown(2);
        }}
        onMouseLeave={() => {
          setOpenDropdown(0);
        }}
        className={` ${styles.panelContainer}`}
      >
        <div
          className={`${styles.tab} ${openDropdown === 2 && styles.activeTab} `}
        >
          {" "}
          <p>Hizmetlerimiz</p>
        </div>
        <div
          className={`${styles.panel} ${
            openDropdown === 2 && styles.openPanel
          }`}
        >
          <ul>
            {hizmetler.map((hizmet) => (
              <Link key={hizmet.id} to={hizmet.path}>
                {hizmet.fullTitle}
              </Link>
            ))}
          </ul>
        </div>
      </div>
      <div
        onMouseOver={() => {
          setOpenDropdown(3);
        }}
        onMouseLeave={() => {
          setOpenDropdown(0);
          setIsExpandedTools(false);
        }}
        className={` ${styles.panelContainer}`}
      >
        <div
          className={`${styles.tab} ${openDropdown === 3 && styles.activeTab} `}
        >
          {" "}
          <p>Daha Fazla</p>
        </div>
        <div
          className={`${styles.panel} ${
            openDropdown === 3 && styles.openPanel
          }`}
        >
          <ul>
            <Link to="/duyurular">Duyurular</Link>
            <Link to="/haberler">Haberler</Link>
            <Link to="/sss">Sıkça Sorulan Sorular</Link>
            <div className={`${styles.innerDropdown} w-100 `}>
              <p
                className={`${isExpandedTools && styles.active} m-0 p-0`}
                onClick={() => {
                  setIsExpandedTools(!isExpandedTools);
                }}
              >
                Araçlar
                <span
                  className={`${styles.dropdownIcon} ${
                    isExpandedTools && styles.active
                  }`}
                >
                  {" "}
                  &gt;
                </span>
              </p>
              {isExpandedTools && (
                <ul>
                  {araclar.map((arac) => (
                    <Link
                      key={arac.id}
                      onMouseOver={() => {
                        setIsShowLınkImg(arac.id);
                      }}
                      onMouseLeave={() => {
                        setIsShowLınkImg(0);
                      }}
                      rel="nofollow"
                      to={arac.url}
                    >
                      {arac.title}
                      <span>
                        {" "}
                        {isShowLinkImg === arac.id && (
                          <img
                            style={{ width: "1.5rem", marginBottom: "0.1rem" }}
                            className=""
                            src="/icons/icon-external-link.webp"
                            alt=""
                          />
                        )}{" "}
                      </span>{" "}
                    </Link>
                  ))}
                </ul>
              )}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavbarDropdowns;
