import { Link } from "react-router-dom";
import styles from "./SectionOne.module.css";
const SectionOne = () => {
  return (
    <section
      className={`${styles.sectionOne} container-fluid position-relative`}
    >
      <picture className={`${styles.backgroundImage}`}>
        <source
          className={`${styles.backgroundImage}`}
          media="(min-width:1200px)"
          srcSet="/images/banner-large.webp"
        />
        <source
          className={`${styles.backgroundImage}`}
          media="(min-width:650px)"
          srcSet="/images/banner-medium.webp"
        />
        <img
          className={`${styles.backgroundImage}`}
          src="/images/banner-small.webp "
          alt="Bağcı Yeminli Mali Müşavirlik - Konya YMM Şirketi"
        />
      </picture>
      <div className={styles.bannerText}>
        <h1>Bağcı Yeminli Mali Müşavirlik</h1>
        <p>İş Dünyasına Uygun Çözümlerle Her Zaman Yanınızdayız!</p>
        <Link to="/iletisim">Bize Ulaşın</Link>
      </div>
    </section>
  );
};

export default SectionOne;
